import React, { useState } from "react"

import useWindowDimensions from "./hooks/window-dimensions" 
import Desktop from "./menu-desktop"
import Mobile from "./menu-mobile"

const Menu = ({ 
  lang, 
  page_links, 
  social_links, 
  global_links, 
  image 
}) => {
  const [drawerOpenState, setDrawerOpenState] = useState(false)

  const { width } = useWindowDimensions()
  const isServer = typeof window === 'undefined'

  if(isServer) {
    return <h1 style={{display: `none`}}>Loading ... </h1>
  }
  if(width < 1024) {
    return (
      <Mobile 
        lang={lang}
        page_links={page_links}
        social_links={social_links}
        global_links={global_links}
        openState={drawerOpenState}
        setOpenState={setDrawerOpenState}
        image={image}
      />)
  } else {
    return (
      <Desktop
        lang={lang}
        page_links={page_links}
        social_links={social_links}
        global_links={global_links}
        openState={drawerOpenState}
        setOpenState={setDrawerOpenState}
        image={image}
      />)
  }
}

export default Menu
