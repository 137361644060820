import React from 'react'
import styled from "@emotion/styled"
import Drawer from 'rc-drawer';

import img from "./images/fg-texture.jpg"
import ornamentTop from "./images/ornament-01.png"
import ornamentBottom from "./images/ornament-02.png"

/*
import Brasserie from "../images/logos/logo-brasserie.png"
import Camion from "../images/logos/logo-camion.png"
import Catering from "../images/logos/logo-catering.png"
import Delivery from "../images/logos/logo-delivery.png"
import Dix30 from "../images/logos/logo-dix30.png"
import Foodtruck from "../images/logos/logo-foodtruck.png"
import Girl from "../images/logos/logo-girl.png"
import HQ from "../images/logos/logo-hq.png"
import Laval from "../images/logos/logo-laval.png"
import Lemonade from "../images/logos/logo-lemonade.png"
import Livraison from "../images/logos/logo-livraison.png"
import Lucilles from "../images/logos/logo-lucilles.png"
import Marche from "../images/logos/logo-marche.png"
import Market from "../images/logos/logo-market.png"
import Monkland from "../images/logos/logo-monkland.png"
import Traiteur from "../images/logos/logo-traiteur.png"


import Camion from "../images/logos/logo-blue-camion.png"
import HQ from "../images/logos/logo-blue-hq.png"
import Livraison from "../images/logos/logo-blue-livraison.png"
import Lucilles from "../images/logos/logo-blue-lucilles.png"
import Marche from "../images/logos/logo-blue-marche.png"
import Monkland from "../images/logos/logo-blue-monkland.png"
import Traiteur from "../images/logos/logo-blue-traiteur.png"
*/

import Catering from "./images/1x/lucilles-catering.png"
import Traiteur from "./images/1x/lucilles-traiteur.png"
import Dix30 from "./images/logos/logo-blue-dix30.png"
import Fairview from "./images/1x/lucilles-fairview.png"
import HQ from "./images/1x/lucilles-hq.png"
import Foodtruck from "./images/logos/logo-blue-foodtruck.png"
import Camion from "./images/logos/logo-blue-camion.png";
import Girl from "./images/1x/lucilles-monkland.png"
import Laval from "./images/logos/logo-blue-laval.png"
import Lemonade from "./images/logos/logo-blue-lemonade.png"
import Market from "./images/logos/logo-blue-market.png"
import Marche from "./images/logos/logo-blue-marche.png";
import Merch from "./images/1x/lucilles-merch.png"
import MT from "./images/1x/lucilles-mont-tremblant.png"
import WG from "./images/1x/lucilles-weekend-grocery.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWindowClose } from "@fortawesome/free-solid-svg-icons"

const Ornament = styled.img`
  max-width: 140px;
  margin: auto;
  display: none;
  @media (min-height: 530px) {
    display: block;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  background-image: url(${img});
  border-radius: 4px;
  padding: 1rem;
  margin: 0;
  z-index:999;
  @media (min-width: 420px) {
    margin: 0px auto;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 1024px) {
  }
`
const FooterLink = styled.div`
  width: 100%;
  padding-bottom: 20px;
  a {
    text-align: center;
    padding: 6px 12px;
    color: #414141;
  }
`
const Outline = styled.div`
  flex: 1 20%;
  border: 1px solid #414141;
  border-radius: 2px;
  padding: 1px;
  margin: 5px;
  &:hover {
    border: 1px solid #fff;
  }
  @media (min-height: 600px) {
    flex: 1 22%;
  }
  @media (min-width: 768px) and (min-height: 790px) {
    flex: 1 28%;
  }
  @media (min-width: 480px) and (max-height: 790px) {
    flex: 1 20%;
  }
`
const Inline = styled.div`
  border: 1px solid #414141;
  padding: 1px;
  &:hover {
    padding: 0px;
    border: 2px solid #00abd4;
  }
  @media (min-width: 420px) {
 }
  @media (min-width: 768px) {
  }
  @media (min-width: 1024px) {
	}
`
const Logo = styled.img`
  padding: 0px;
  margin: 0;
  max-height: 92px;
  @media (min-width: 420px) and (max-height: 420px) {
    max-height: 60px;
  }
`
const Link = styled.a`
  padding: 0;
`
const MenuClose = styled.div`
  flex: 1;
  text-align: right;
  cursor: pointer;
  padding: 4px 10px;
  font-size: 16px;
  color: #00abd4;
  @media (min-width: 480px) {
    font-size: 16px;
  }
  @media (min-width: 768px) {
    padding: 16px;
    font-size: 24px;
  }
  @media (min-height: 530px) {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 12px;
  }
`

const MenuDrawer = ({
    lang,
    openState, 
    setOpenState, 
    width,
    wrapperClassName,
    closeButton
  }) => {

  const data = {
    fr: [{
      href: "//dive.lucillesoyster.com",
      src: Girl
    }, {
      href: "//laval.lucillesoyster.com",
      src: Laval
    }, {
      href: "//dix30.lucillesoyster.com",
      src: Dix30
    }, {
      href: "//fairview.lucillesoyster.com/",
      src: Fairview
    }, {
      href: "//hq.lucillesoyster.com/",
      src: HQ
    }, {
      href: "//market.lucillesoyster.com",
      src: Marche
    }, {
      href: "//www.lucyslemonade.com",
      src: Lemonade
    }, {
      href: "//foodtruck.lucillesoyster.com",
      src: Camion
    }, {
      href: "//catering.lucillesoyster.com",
      src: Traiteur
    }, {
      href: "//mt.lucillesoyster.com",
      src: MT
    }],
    en: [{
      href: "//dive.lucillesoyster.com/en/",
      src: Girl
    }, {
      href: "//laval.lucillesoyster.com/en/",
      src: Laval
    }, {
      href: "//dix30.lucillesoyster.com/en/",
      src: Dix30
    }, {
      href: "//fairview.lucillesoyster.com/en/",
      src: Fairview
    }, {
      href: "//hq.lucillesoyster.com/",
      src: HQ
    }, {
      href: "//market.lucillesoyster.com/en/",
      src: Market
    }, {
      href: "//www.lucyslemonade.com/en/",
      src: Lemonade
    }, {
      href: "//foodtruck.lucillesoyster.com/en/",
      src: Foodtruck
    }, {
      href: "//catering.lucillesoyster.com/en/",
      src: Catering
    }, {
      href: "//mt.lucillesoyster.com",
      src: MT
    }]
  }

  const main_link = {
    fr: {
      label: "Retour à la page d'accueil",
      link: "//www.lucillesoyster.com",
    },
    en: {
      label: "Back to Main",
      link: "//www.lucillesoyster.com/en/",
    }
  }
  return (
    <Drawer 
      width={!width ? "40vw" : width}
      handler={false} 
      open={openState} 
      onClose={() => (setOpenState(false))}
      wrapperClassName={
        !wrapperClassName ? "menu-drawer-wrapper" 
          : wrapperClassName
      }
    >
      { closeButton &&
        <MenuClose
          onClick={()=> (
            setOpenState(false) 
          )}>
          <FontAwesomeIcon icon={faWindowClose}/>
        </MenuClose>
      }

      <Ornament src={ornamentTop} />
      <Wrapper>
        { data[lang].map((e) => (
            <Outline>
              <Inline>
                <Link href={e.href}>
                  <Logo src={e.src} />
                </Link>
              </Inline>
            </Outline>
          ))
        }
      </Wrapper>
      <FooterLink>
        <a href={main_link[lang].link}>
          {main_link[lang].label}
        </a>
      </FooterLink>
      <Ornament src={ornamentBottom} />
    </Drawer>
  )
}

export default MenuDrawer
