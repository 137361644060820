import React from "react"
import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"

import Catering from "../images/logos/logo-blue-catering.png";
import Delivery from "../images/logos/logo-blue-delivery.png";
import Dix30 from "../images/logos/logo-blue-dix30.png";
import Foodtruck from "../images/logos/logo-blue-foodtruck.png";
import Girl from "../images/logos/logo-blue-girl.png";
import Laval from "../images/logos/logo-blue-laval.png";
import Lemonade from "../images/logos/logo-blue-lemonade.png";
import Market from "../images/logos/logo-blue-market.png";

export const global_links = [
  {
    href: "//dive.lucillesoyster.com",
    src: Girl
  }, {
    href: "//laval.lucillesoyster.com",
    src: Laval
  }, {
    href: "//dix30.lucillesoyster.com",
    src: Dix30
  }, {
    href: "//market.lucillesoyster.com",
    src: Market
  }, {
    href: "//www.lucyslemonade.com",
    src: Lemonade
  }, {
    href: "//foodtruck.lucillesoyster.com",
    src: Foodtruck
  }, {
    href: "//catering.lucillesoyster.com",
    src: Catering
  }, {
    href: "//delivery.lucillesoyster.com",
    src: Delivery
  }
];

export const social_links = [
  {
    name: "Facebook",
    href: "//www.facebook.com/pages/Lucilles-Oyster-Dive/200515528427",
    icon: faFacebook
  },
  {
    name: "Instagram",
    href: "//www.instagram.com/lucillesdix30/",
    icon: faInstagram
  }
]

export const page_links = {
  en: [
    {
      name: "Menu",
      ref: React.createRef(),
    },
    {
      name: "Contact",
      ref: React.createRef(),
    },
  ],
  fr: [
    {
      name: "Menu",
      ref: React.createRef(),
    },
    {
      name: "Contact",
      ref: React.createRef(),
    },
  ]
}

export const INDEX_PAGEKEYS_MENU    = 0
export const INDEX_PAGEKEYS_CONTACT = 1
export const INDEXLEN_PAGEKEYS      = 2
