module.exports = {
  fr: {
    default: true,
    path: `fr`,
    locale: `fr-CA`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `fr`,
    ogLanguage: `fr_CA`,
    defaultTitle: `Restaurants et services`,
    defaultDescription: `Découvrez les offres culinaires du groupe Lucille's`,
  },
  en: {
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Restaurants and Services`,
    defaultDescription: `Explore the culinary offerings of Lucille's and co.`,
  },
}
