import { useState, useEffect, useCallback } from 'react'

export default function useWindowDimensions() {

  const hasWindow = typeof window !== 'undefined'

  const getWindowDimensions = useCallback(() => {
    let width = hasWindow ? window.innerWidth : null;
    let height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height
    };
  }, [hasWindow])

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getWindowDimensions, hasWindow]);

  return windowDimensions;
}
