import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LocaleContext } from "./layout"

const useTranslations = () => {
  // Grab the locale (passed through context) from the Context Provider
  const { locale } = React.useContext(LocaleContext)
  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData } = useStaticQuery(query)

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map(item => {
    return {
      name: item.node.name,
      translations: item.node.translations,
    }
  })

  // Only return translations for the current locale
  const { translations } = simplified.filter(lang => lang.name === locale)[0]

  return translations
}

export default useTranslations

const query = graphql`
  query useTranslations {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            description
            CONTACT
            order_now
            call_to_action
            book_online
            headline
            message_1
            message_2
						close_message
            donate
            footer_headline
            name
            careers_page_title
            careers_page_content
            button_apply
            special_events_page_title
            special_events_page_content
            restaurants_and_services {
              name
              items {
                name
                url
                local
              }
            }
            local_links {
              name
              items {
                name
                url
                local
              }
            }
            global_links {
              name
              items {
                name
                url
                local
              }
            }
            rawbar {
              name
              items {
                name
                description
                price
              }
            }
            starters {
              name
              items {
                name
                description
                price
              }
            }
            appetizers {
              name
              items {
                name
                description
                price
              }
            }
            mains {
              name
              items {
                name
                description
                price
              }
            }
            steaks {
              name
              items {
                name
                description
                price
              }
            }
            sides {
              name
              items {
                name
                price
              }
            }
            desserts {
              name
              items {
                name
                price
              }
            }
            details
            contact_title
            hours_title
            label_monday
            hours_monday
            label_tuesday
            hours_tuesday
            label_wednesday
            hours_wednesday
            label_thursday
            hours_thursday
            label_friday
            hours_friday
            label_saturday
            hours_saturday
            label_sunday
            hours_sunday
            contact_links {
              name
              items {
                name
                url
                local
              }
            }
          }
        }
      }
    }
  }
`
