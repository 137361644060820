import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
//import { Menu } from "@mrmaru/lucilles-components/dist/index"
import Menu from "./menu/menu"
import Footer from "./footer"
import "./layout.css"
import img from "../images/bg-texture.jpg"
import Image from "../components/image-logo-lucilles"

import { page_links, social_links } from "../data/assets"

import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;

const LocaleContext = React.createContext()

const Layout = ({ children, pageName, pageContext: { locale } }) => {

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
          sitePage {
            path
          }
          allSitePage{
            edges{
              node{
                path
              }
            }
          }
        }
      `}
      render={data => (
        <LocaleContext.Provider value={{ locale, page_links: page_links[locale] }}>
          <>
            <Menu lang={locale} page_links={page_links[locale]} social_links={social_links} global_links={social_links} image={Image} />
            <main id={pageName} style={{ backgroundColor: '#141414' }}>{children}</main>
            <Footer />
          </>
        </LocaleContext.Provider>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout, LocaleContext }
